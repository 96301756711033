<template>
  <VContainer class="main--body--content">
    <div class="back-menu align-center">
      <a
        @click="$router.go(-1)"
        href="javascript:void('back')"
        :to="{ name: 'Docs' }"
      >
        <VIcon class="mr-2" dense>fa-caret-left</VIcon>
        <span>Back</span>
      </a>
    </div>

    <form class="form" @submit.prevent="newCategory">
      <VRow>
        <VCol cols="6">
          <VAutocomplete
            v-model="form.category_id"
            :items="getCategoriesDropdown"
            item-value="id"
            item-text="title"
            label="Choose a category"
            outlined
            dense
          />
        </VCol>
        <VCol cols="6">
          <VTextField
            outlined
            dense
            label="Title"
            placeholder="Enter the title"
            v-model="form.title"
            :required="true"
          />
        </VCol>
        <VCol cols="6">
          <VTextField
            outlined
            dense
            label="Description"
            placeholder="Enter the description, it will help better searcb"
            v-model="form.description"
          />
        </VCol>
        <VCol cols="6">
          <VTextField
            outlined
            dense
            type="number"
            label="Position"
            placeholder="1.0000000"
            v-model.number="form.position"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          <div class="d-flex align-center justify-center">
            <VBtn color="primary" type="submit">Create</VBtn>
          </div>
        </VCol>
      </VRow>
    </form>
  </VContainer>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import docsAPI from "@/api/internal/docs.js";

export default {
  name: "CategoryNew",
  components: {},
  data() {
    return {
      form: {
        category_id: null,
        title: null,
        description: null,
        position: null
      },
      dataForm: ""
    };
  },
  computed: {
    ...mapState({
      categories: state => state.docs.categories.list
    }),
    getCategoriesDropdown() {
      const tree = this.categories
        .filter(x => !x.category_id)
        .map(x => ({
          ...x,
          children: this.getChildren(this.categories, x.id)
        }));
      return this.flatternCategories(tree);
    },
    submitForm() {
      let newForm = JSON.parse(JSON.stringify(this.form));
      try {
        newForm.title = (newForm.title || "").trim();
      } catch (error) {}

      return newForm;
    }
  },
  methods: {
    ...mapMutations({
      OpenLoading: "OpenLoading",
      CloseLoading: "CloseLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      CreateNewCategory: "DOCS_ADD_CATEGORY"
    }),
    ...mapActions({
      fetchCategories: "fetchCategories"
    }),
    flatternCategories(array, result, parentTitle = "") {
      if (!result) {
        result = [];
      }

      array.forEach(e => {
        let found = result.find(item => item.id == e.id);
        if (!found) {
          let title = "";
          if (!parentTitle) {
            title = e.title;
          } else {
            title = parentTitle + ` > ${e.title}`;
          }
          result.push({ id: e.id, title: title });
          if (e.children && e.children.length) {
            return this.flatternCategories(e.children, result, title);
          }
        }
      });

      return result;
    },
    getChildren(array, id) {
      return array
        .filter(x => x.category_id == id)
        .map(x => ({
          ...x,
          children: this.getChildren(array, x.id)
        }));
    },
    recalcDataExport(form = this.form) {
      try {
        let newForm = JSON.parse(JSON.stringify(form));
        this.dataForm = JSON.stringify(newForm, function(key, value) {
          if (typeof value === "object") return JSON.stringify(value);
          return value;
        });
      } catch (error) {
        this.dataForm = null;
      }
    },
    newCategory() {
      this.OpenLoading();
      console.log(1);
      this.recalcDataExport();
      console.log(2);
      docsAPI
        .createCategory(this.submitForm)
        .then(res => {
          this.CreateNewCategory(res.data.category);
          this.OpenSuccess({
            message: "Create new Category successfully!",
            time: 2000
          });
          this.$router.push({
            name: "Edit-Category",
            params: { categoryId: res.data.category.id }
          });
          this.CloseLoading();
        })
        .catch(err => {
          this.CloseLoading();
          this.OpenError({ message: `<p>${err}</p>` });
        });
    }
  },
  created() {
    if (!this.categories || !this.categories.length) {
      this.fetchCategories();
    }
  },
  mounted() {},
  beforeDestroy() {},
  watch: {
    form: {
      deep: true,
      handler(newV) {
        this.recalcDataExport(newV);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.gf_section-2 .main--body .form {
  padding: 4em 0;
}
</style>
